<template>
    <div>
        <div class="flex flex-row justify-content-between align-items-center">
            <div class="w-[50%]">
                <b-tabs pills v-model="tab">
                    <b-tab title="اختبارات التعليم الجامعي" active> </b-tab>
                    <b-tab title="اختبارات التعليم المدرسي"> </b-tab>
                </b-tabs>
            </div>
            <div class="w-[50%]">
                <div class="flex" style="margin-top: -18px">
                    <ek-dialog
                        v-if="tab == 0"
                        ref="newdialog"
                        title="استيراد اختبارات التعليم الجامعي"
                        size="md"
                        btnText="استيراد اختبارات التعليم الجامعي "
                        @ok="ok()"
                    >
                        <template #body>
                            <validationObserver ref="newdialogForm">
                                <ek-input-image
                                    class="mt-1"
                                    ref="imgFile"
                                    label=" الملف  "
                                    name="الملف "
                                    :accept="'.xlsx, .xls, .csv'"

                                    @input="storeExcelFile($event)"
                                >
                                    <h5>انقر لتحميل الملف</h5>
                                </ek-input-image>
                                <ek-input-select
                                    v-model="SubjectId"
                                    label="المادة"
                                    placeholder="اختر المادة "
                                    :options="subjectsNames"
                                    name="select"
                                    :clearable="true"
                                />
                            </validationObserver>
                        </template>
                    </ek-dialog>
                    <ek-dialog
                        v-if="tab == 1"
                        ref="newExceldialog"
                        title="استيراد اختبارات التعليم الاساسي"
                        size="md"
                        btnText="استيراد اختبارات التعليم الاساسي "
                        @ok="AddExcel()"
                    >
                        <template #body>
                            <validationObserver ref="newdialogFormForExcel">
                                <ek-input-image
                                    class="mt-1"
                                    ref="imgFile"
                                    label=" الملف  "
                                    name="الملف "
                                    v-model="SubjectId"

                                    @input="storeExcelFile($event)"
                                >
                                    <h5>انقر لتحميل الملف</h5>
                                </ek-input-image>
                                <ek-input-select
                                    v-model="SubjectId"
                                    label="المادة"
                                    placeholder="اختر المادة "
                                    :options="subjectsNames"
                                    name="select"
                                    :clearable="true"
                                />
                            </validationObserver>
                        </template>
                    </ek-dialog>
                </div>
            </div>
        </div>
        <!-- {{CourseQuestionsList[0]}} -->
        <ek-table
            :columns="columns"
            :items="CourseQuestionsList"
            @delete-selected="deleteCourseQuestion"
        >
            <template slot="items.fileUrl" slot-scope="{ value }">
                <span>
                    <img
                        style="
                            width: 50px;
                            height: 50px;
                            object-fit: cover;
                            border-radius: 50px;
                        "
                        :src="$store.getters['app/domainHost'] + '/' + value"
                        alt=""
                        srcset=""
                /></span>
            </template>
            <template slot="items.hint" slot-scope="{ value }">
                <span>
                    {{ value.text }}
                </span>
            </template>
            <template slot="items.hintFileUrl" slot-scope="{ props }">
                <span v-if="props.row.hint.fileUrl">
                    <img
                        style="
                            width: 50px;
                            height: 50px;
                            object-fit: cover;
                            border-radius: 50px;
                        "
                        :src="
                            $store.getters['app/domainHost'] +
                            '/' +
                            props.row.hint.fileUrl
                        "
                        alt=""
                        srcset=""
                    />
                </span>
                <span v-else>----- </span>
            </template>

            <template slot="items.tags" slot-scope="{ props }">
                <b-button
                    :id="`popover-target-${props.row.tags}`"
                    variant="transperant"
                >
                    مرر لرؤية المواد
                </b-button>
                <b-popover
                    :target="`popover-target-${props.row.tags}`"
                    triggers="hover"
                    placement="right"
                >
                    <span>
                        {{
                            props.row.tags.length > 0
                                ? props.row.tags.join(",")
                                : "لايوجد"
                        }}
                    </span>
                </b-popover>
            </template>
            <template slot="items.details" slot-scope="{ props }">
                <b-button
                    @click="goToDetails(props.row)"
                    variant="flat-primary"
                    class="rounded-circle p-25"
                >
                    <unicon name="ellipsis-v" fill="gray"></unicon>
                </b-button>
            </template>
        </ek-table>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            ids: [],
            tab: 0,
            file: null,
            SubjectId: null,
        };
    },

    computed: {
        ...mapState({
            CourseQuestion: ({ CourseQuestion }) =>
                CourseQuestion.CourseQuestion,
            columns: (state) => state.courseQuestion.columns,
            subjectsNames: ({ subjects }) => subjects.subjectsNames,
        }),
        ...mapGetters(["CourseQuestionsList"]),
    },
    methods: {
        ...mapActions([
            "getACourseQuestionsList",
            "deleteCourseQuestion",
            "getSubjectsNames",
            "AddFromExcel",
            "AddFromSchoolExcel",
            "getSubjects",
        ]),
        formDate(startDate) {
            let dete = `${new Date(startDate).toLocaleDateString()}`;
            return dete;
        },
        ok() {
            this.$refs.newdialogForm.validate().then((success) => {
                if (success) {
                    console.log(this.file, this.SubjectId);
                    this.AddFromExcel({
                        PreviousExamFile: this.file,
                        SubjectId: this.SubjectId,
                        previousExamType: "QuestionBank"
                    });
                }
            });
        },
        storeExcelFile(event) {
            console.log(event);
            if (event && event.file) {
                this.file = event.file;
            }
        },
        AddExcel() {
            this.$refs.newdialogFormForExcel.validate().then((success) => {
                if (success) {
                    console.log(this.file, this.SubjectId);
                    this.AddFromSchoolExcel({
                        PreviousExamFile: this.file,
                        SubjectId: this.SubjectId,
                        previousExamType: "QuestionBank"
                    });
                }
            });
        },
        formDat(endDate) {
            let dete = `${new Date(endDate).toLocaleDateString()}`;
            return dete;
        },
        editCourseQuestionsList() {
            this.$store.commit("IS_DIALOG_OPEN", true);
            //   this.$store.commit("SET_CQ_DTO", dto);
        },
        goToDetails(row) {
            this.$router.push(`/admin/courseQuestion/${row.id}`);
        },
    },
    created() {
        this.getACourseQuestionsList(null);
        this.getSubjectsNames(1);
    },
    watch: {
        tab: function (newTab) {
            if (newTab === 0) {
                this.getACourseQuestionsList(null);
            } else {
                this.getACourseQuestionsList(null);
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.card-body {
    padding: 0px !important;
}
.card .card-title {
    margin-bottom: 0.53rem;
    padding: 12px;
}
.card .card-text {
    padding: 0px 12px;
}
</style>
